<template>
  <div v-if="book.type != 'free'">
    <v-tooltip 
      v-if="book.type == 'limited'"
      top
      color="purple"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-chip
          color="purple"
          dark
          v-bind="attrs"
          v-on="on"
        >
          <v-icon small>mdi-wallet-giftcard</v-icon>
        </v-chip>
      </template>
      <span>Limited Edition</span>
    </v-tooltip>
    <v-tooltip 
      v-else-if="book.type == 'premium'" 
      top
      color="yellow"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-chip
          color="yellow"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon small>mdi-crown</v-icon>
        </v-chip>
      </template>
      <span>Event Special</span>
    </v-tooltip>
    <v-tooltip 
      v-else-if="book.type == 'event'" 
      top
      color="brown"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-chip
          color="brown"
          dark
          v-bind="attrs"
          v-on="on"
        >
          <v-icon small>mdi-calendar</v-icon>
        </v-chip>
      </template>
      <span>Event Special</span>
    </v-tooltip>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components:{
    //
  },
  computed: mapState({
    //
  }),
  props:[
    "book",
  ],
  data: () => ({
    //
  }),
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    //
  }
}
</script>